import { Table, Button, Input, Space, Divider, Typography } from "antd"
import React, { useEffect, useState } from "react"

const { Text } = Typography

const MessageContent = ({ content, content_type, content_attributes }) => {
  return (
    <>
      <div className="text-[#343434] mt-2">{content}</div>
    </>
  )
}

export default MessageContent
