import React, { useState } from "react"
import { Button, Divider, Space, Form, Input } from "antd"
import { sendMessage } from "../../../../services/user"

const FormChat = ({ infomationConservation }) => {
  const [form] = Form.useForm()
  const [disableBtn, setDisableBtn] = useState(true)
  const onFinish = ({ message }) => {
    console.log(message)
    if (message?.trim()) {
      sendMessage(
        {},
        {
          content: message?.trim(),
          message_type: "outgoing",
          content_type: "text",
          store_id: infomationConservation.seller_id,
          conversation_id: infomationConservation.conversation_id,
          store_partner_id: infomationConservation.buyer_id,
        }
      )
        .then(({ data }) => {
          form.setFieldsValue({ message: "" })
          setDisableBtn(true)
        })
        .catch((err) => console.log(err))
    }
    form.setFieldsValue({ message: "" })
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      if (e.target.value) {
        form.submit()
      }
    }
  }
  return (
    <Form
      style={{ marginTop: 10, marginBottom: 40 }}
      onFinish={onFinish}
      form={form}
    >
      <Form.Item name="message">
        <Input.TextArea
          placeholder="メッセージを入力してください。"
          rows={4}
          onKeyUp={handleKeyPress}
          onChange={(e) => setDisableBtn(!e.target.value)}
        />
      </Form.Item>
      <Divider />
      <Space
        size={10}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5rem",
        }}
      >
        <Button
          disabled={disableBtn}
          htmlType="submit"
          type="primary"
          style={{ width: 329, borderRadius: 5 }}
        >
          送信する
        </Button>
      </Space>
    </Form>
  )
}

export default FormChat
