import { Typography } from "antd"
import React from "react"

const { Text } = Typography

const MessageContent = ({ content, time }) => {
  return (
    <div className="flex items-end justify-between mb-[10px]">
      <div className="text-[#343434] bg-[#F5F6F8] py-1 px-2 rounded-2xl text-13 messContent">
        {content}
      </div>
      <Text className="text-[11px] !text-[#595959] ml-2">{time}</Text>
    </div>
  )
}

export default MessageContent
