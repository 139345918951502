import { Spin } from "antd"
import React, { useCallback, useEffect, useState } from "react"
import { checkConversation } from "../../../services/chat"
import { getStoreById } from "../../../services/user"
import Chat from "./Chat"
const AdminChat = ({ store_id }) => {
  const [nameBuyer, setNameBuyer] = useState(null)

  console.log("storeId", store_id)
  const [isLoadingData, setIsLoadingData] = useState(false)

  const [infomationConservation, setInfomationConservation] = useState(null)

  useEffect(() => {
    setIsLoadingData(true)

    if (store_id && !infomationConservation) {
      ;(async function () {
        const { response } = await checkConversation({
          store_id: "",
          store_partner_id: store_id,
        })
        if (response.status == 200) {
          if (response.data) {
            setInfomationConservation(response.data)

            setIsLoadingData(false)
          }
        }
      })()
    }
  }, [store_id])

  const getDataInfoAdmin = useCallback(async () => {
    if (store_id) {
      const { response } = await getStoreById(store_id)
      if (response.status === 200) {
        setNameBuyer(response.data?.stores?.name)
      }
    }
  }, [store_id])

  useEffect(() => {
    getDataInfoAdmin()
  }, [getDataInfoAdmin])

  return (
    <Spin spinning={isLoadingData}>
      {infomationConservation && nameBuyer && (
        <Chat
          infomationConservation={infomationConservation}
          nameBuyer={nameBuyer}
          store_id={store_id}
        />
      )}
    </Spin>
  )
}

export default AdminChat
